import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

// Utilities
import slugify from "@sindresorhus/slugify"

import Layout from '../../../components/layout/Layout'
import ProduitCard from '../../../components/card/Produit'

// SousCategories
const SousCategories = ({ pageContext, data }) => {
  const { categorie, sous_categorie } = pageContext
  const { edges, totalCount } = data.sousCategories
  const pageTitle = `${sous_categorie} - ${categorie} - ${totalCount} produit${
    totalCount === 1 ? "" : "s"
  }`
  const header = `${totalCount} produit${
    totalCount === 1 ? "" : "s"
  } dans la sous catégorie "${sous_categorie}"`

  return (
    <Layout pageTitle={pageTitle} headerClassPosition="sticky-top">
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2>{header}</h2>
          <ol>
            <li>
              <Link to={`/`} className="text-reset">Accueil</Link>
            </li>
            <li>
              <Link to={`/produits`} className="text-reset">Produits</Link>
            </li>
            <li>
              <Link to={`/produits/categories`} className="text-reset">Catégories</Link>
            </li>
            <li>
              <Link to={`/produits/categories/${slugify(categorie)}`} className="text-reset">{categorie}</Link>
            </li>
          </ol>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="row">
          {
             edges.map((produit, index) => (
              <ProduitCard key={index} data={produit.node}/>
            ))
          }
        </div>
      </div>
    </section>
  </Layout>
  )
}

SousCategories.propTypes = {
  pageContext: PropTypes.shape({
    categorie: PropTypes.string.isRequired,
    sous_categorie: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    sousCategories: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              titre: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default SousCategories

export const query = graphql`
  query($categorie: String, $sous_categorie: String) {
    sousCategories: allMdx(
      sort: {fields: frontmatter___date, order: DESC}
      filter: { 
        fileAbsolutePath: { regex: "/produits/"},
        frontmatter: { categorie: { eq: $categorie }, sous_categorie: { eq: $sous_categorie }, actif: { eq: true } }
      }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            titre
            marque
            categorie
            sous_categorie
            card_image {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
            card_image_alt
            hero_image {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
            hero_image_alt
            hero_image_credit_link
            hero_image_credit_text
            accueil
            actif
            date(formatString: "MMMM D, YYYY")            
          }
          id
          slug
        }
      }
    }
  }
`